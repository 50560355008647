/** @jsx jsx **/
import { jsx, Box, Container, Text, Heading } from 'theme-ui'
import { FC } from 'react'
import Layout from '~/components/layout'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import GridPhotoWithListItem from '~/components/grid-photo-with-list-item'
import theme from '~/gatsby-plugin-theme-ui'
import { GatsbyImageType } from '~/@types/models'
import HeadingV2 from '~/componentsV2/heading'
import About, { IAboutData } from '~/componentsV2/about'

type Props = {}

type AboutPageType = {
  pageData: {
    frontmatter: IAboutData
  }
}

const AboutPage: FC<Props> = () => {
  const query = useStaticQuery<AboutPageType>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "about" } }) {
        frontmatter {
          heroBackground {
            childImageSharp {
              gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          contentTitle
          content
          contentLinkText
          contentLink
          items {
            title
            content
            image {
              childImageSharp {
                gatsbyImageData(width: 960, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
            linkText
            link
          }
        }
      }
    }
  `)

  return <About data={query.pageData.frontmatter} />
}

export default AboutPage
