import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image'
import React from 'react'
import Layout from '~/components/layout'
import { Container, Divider, HeroSection, HeroSectionImage, Content, ContentItems } from './styles'
import { H1, H2, H3, H4, H5, Paragraph } from '~/styled/components/typography'
import { Box, Grid, Text } from 'theme-ui'
import { camelCase } from 'lodash'
import { CustomRugStudioForm } from '~/utils/customRugStudio'
import { Link } from 'gatsby'

export interface IAboutData {
  heroBackground: ImageDataLike
  contentTitle: string
  content: string
  contentLinkText: string
  contentLink: string
  items: {
    title: string
    content: string
    image: ImageDataLike
    linkText: string
    link: string
  }[]
}

export interface AboutProps {
  data: IAboutData
}

const About: React.FC<AboutProps> = ({ data }) => {
  const { contentTitle, content, contentLinkText, contentLink, items } = data
  const heroBackground = getImage(data.heroBackground)

  return (
    <Layout
      headerVariant="default"
      title={contentTitle}
      description={content}
      topBanner={false}
      stickyMenu={true}
      headerSxProps={{
        '.desktop': {
          height: 136,
        },
        '.mobile-content': {
          height: 90,
        },
      }}
      sx={{
        pt: [90, null, null, 136],
      }}
    >
      <HeroSection>
        {heroBackground && (
          <HeroSectionImage>
            <GatsbyImage image={heroBackground} alt={contentTitle} />
          </HeroSectionImage>
        )}
      </HeroSection>

      <Container>
        <Content>
          <H1>{contentTitle}</H1>
          <Paragraph dangerouslySetInnerHTML={{ __html: content }} />
          <Link to={contentLink}>{contentLinkText}</Link>
        </Content>

        <Divider />

        <ContentItems>
          {items.map((item, index) => {
            const image = getImage(item.image)

            return (
              <Grid className="item" columns={[1, 2]} gap={0}>
                <H1 className="hide-desktop" dangerouslySetInnerHTML={{ __html: item.title }} />
                {image && <GatsbyImage image={image} alt={item.title} />}
                <Box
                  sx={{
                    px: [20, 140],
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <H1 className="hide-mobile" dangerouslySetInnerHTML={{ __html: item.title }} />
                  <Paragraph dangerouslySetInnerHTML={{ __html: item.content }} />
                  <Link to={item.link}>{item.linkText}</Link>
                </Box>
              </Grid>
            )
          })}
        </ContentItems>
      </Container>
    </Layout>
  )
}

export default About
