import styled, { css } from 'styled-components'
import { Paragraph } from '~/styled/components/typography'

export const HeroSection = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1920 / 480;
  min-height: 480px;

  ${({ theme }) => '@media ' + theme.mediaQueries.maxMd} {
    min-height: 170px;
    aspect-ratio: 1920 / 170;
  }
`

export const HeroSectionImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

export const Container = styled.div`
  max-width: 1920px;
  margin: 0 auto;

  padding-top: 64px;
  box-sizing: border-box;

  color: #666666;

  a {
    color: #666666;
  }

  h1,
  h2,
  h3 {
    margin-bottom: 30px;
    color: #2b2b2b;
  }

  p {
    line-height: 35px;
    margin-top: 0;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 42px;
  }

  .hide-desktop {
    display: none;
  }

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    padding-top: 34px;
    padding-bottom: 34px;

    h1,
    h2,
    h3 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      line-height: 30px;
    }

    h1 {
      font-size: 28px;
    }

    .hide-mobile {
      display: none;
    }

    .hide-desktop {
      display: block;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 1050px;
  margin: 0 auto 90px;
  padding: 0 20px;

  h1 {
    font-size: 42px;
  }

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    h1 {
      font-size: 28px;
    }

    margin-bottom: 60px;
  }
`

export const ContentItems = styled.div`
  .item {
    &:nth-child(even) {
      .gatsby-image-wrapper {
        order: 2;
      }
    }

    .gatsby-image-wrapper {
      aspect-ratio: 1/1;
    }

    ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
      margin-bottom: 50px;

      &:nth-child(even) {
        .gatsby-image-wrapper {
          order: 0;
        }
      }

      .gatsby-image-wrapper {
        margin: 0 20px 20px;
      }

      .hide-desktop {
        margin: 0 20px 20px;
      }
    }
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 100%;
  border-bottom: 2px rgba(0, 0, 0, 0.1) solid;
  margin-top: 20px;

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    display: none;
  }
`
